<template>
  <div class="dark">
    <div class="breadcrumb-area">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item class="islink"
                            @click="spaceReturn()">空间管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增空间</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="frame-row">
      <div class="frame-col fixed"
           style="width:auto">
        <div class="add-left">
          <div class="add-link">请根据右侧面板信息快速构建一个空间，<span @click="jumpHelp()">操作文档</span></div>
        </div>
      </div>
      <div class="frame-col">
        <el-scrollbar>
          <div class="radio-content">
            <space-empty></space-empty>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import SpaceEmpty from './childComps/SpaceEmpty.vue'

export default {
  name: "SpaceAdd",
  components: {
    SpaceEmpty,
  },
  data () {
    return {
      tabPosition: "0"
    }
  },
  methods: {
    spaceReturn () {
      window.history.go(-1);
    },
    jumpHelp() {
      window.open('https://vls-docs.blueearth.com.cn/guide/quick-start/space.html');
    }
  }
}
</script>

<style lang="scss" scoped>
.dark {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;

  .frame-row {
    flex: 1;
  }
}

.add-left {
  width: 360px;
  height: calc(100vh - 240px);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 32px;

  .add-link {
    width: 100%;
    float: left;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);

    span {
      color: rgb(0, 130, 255);
      cursor: pointer;

      &:hover {
        color: rgb(30, 170, 255);
      }
    }
  }
}

.radio-content {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    width: 100%;
    height: 200px;
  }
}
</style>